import { router as globalRouter, routeName } from '@/router';
import type { RouteLocationRaw } from 'vue-router';
import { useRouter } from 'vue-router';

export function useRouterPush(inSetup = true) {
  const router = inSetup ? useRouter() : globalRouter;
  const route = globalRouter.currentRoute;

  function routerPush(to: RouteLocationRaw, newTab = false) {
    if (newTab) {
      const routerData = router.resolve(to);
      window.open(routerData.href, '_blank');
    } else {
      router.push(to);
    }
  }

  function routerBack() {
    router.go(-1);
  }

  function toHome(newTab = false) {
    routerPush({ name: routeName('root') }, newTab);
  }

  function toLogin(loginModule?: EnumType.LoginModuleKey, redirectUrl?: string) {
    const module: EnumType.LoginModuleKey = loginModule || 'sign-in';
    const routeLocation: RouteLocationRaw = {
      name: routeName('login'),
      params: { module }
    };
    Object.assign(routeLocation);
    routerPush(routeLocation);
  }

  function toLoginModule(module: EnumType.LoginModuleKey) {
    const { query } = route.value;
    routerPush({ name: routeName('login'), params: { module }, query });
  }

  function toLoginRedirect() {

    const { query } = route.value;
    if (query?.redirect) {
      routerPush(query.redirect as string);
    } else {
      toHome();
    }
  }

  return {
    routerPush,
    routerBack,
    toHome,
    toLogin,
    toLoginModule,
    toLoginRedirect
  };
}
