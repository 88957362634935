import { routeName } from '@/router';
import { useAuthStore } from '@/store';
import { exeStrategyActions } from '@/utils';
import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { isLoggedIn } from '~/src/store/auth/helpers';
import { createDynamicRouteGuard } from './dynamic';

export async function createPermissionGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const permission = await createDynamicRouteGuard(to, from, next);
  if (!permission) return;

  if (to.meta.href) {
    window.open(to.meta.href);
    next({ path: from.fullPath, replace: true, query: from.query });
    return;
  }

  const auth = useAuthStore();
  const isLogin = isLoggedIn()
  // const isLogin = auth.isLogin

  const permissions = to.meta.permissions || [];
  const needLogin = Boolean(to.meta?.requiresAuth) || Boolean(permissions.length);
  const hasPermission = !permissions.length || permissions.includes(auth.userInfo.role);

  const actions: Common.StrategyAction[] = [
    [
      (isLogin && to.name) === routeName('login'),
      () => {
        next({ name: routeName('root') });
      }
    ],
    [
      !needLogin,
      () => {
        next();
      }
    ],
    [
      !isLogin && needLogin,
      () => {
        next({ name: routeName('login') });
      }
    ],
    [
      (isLogin && needLogin) && hasPermission,
      () => {
        next();
      }
    ],
    [
      (isLogin && needLogin) && !hasPermission,
      () => {
        next({ name: routeName('403') });
      }
    ]
  ];

  exeStrategyActions(actions);
}
