
// Components
import App from './App.vue'
// Composables
import "@/assets/scss/theme.scss"
import "animate.css/animate.min.css"
import 'vue-image-zoomer/dist/style.css'

import 'leaflet/dist/leaflet.css'
import { VueImageZoomer } from 'vue-image-zoomer'
// import { GoogleMap, Marker } from 'vue3-google-map'
import { vuetifyProTipTap } from './tiptap'

// Fix Leaflet's default icon paths
import { Icon } from 'leaflet'
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png'
import markerIcon from 'leaflet/dist/images/marker-icon.png'
import markerShadow from 'leaflet/dist/images/marker-shadow.png'

Icon.Default.mergeOptions({
  iconRetinaUrl: markerIcon2x,
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});

async function setupApp() {
  const app = createApp(App)
  setupStore(app)
  await setupRouter(app)

  app.use(VueImageZoomer)
  // app.component('GoogleMap', GoogleMap)
  // app.component('GoogleMapMarker', Marker)
  app.use(vuetifyProTipTap)
  
  registerFilters(app)
  registerPlugins(app)
  app.mount('#app')
}

setupApp()
