// import { markRaw } from 'vue'
import { BaseKit, Blockquote, Bold, BulletList, Clear, Code, CodeBlock, Color, FontFamily, FontSize, Fullscreen, Heading, Highlight, History, HorizontalRule, Indent, Italic, Link, OrderedList, Strike, SubAndSuperScript, Table, TaskList, TextAlign, Underline, Video, VuetifyTiptap, VuetifyViewer, createVuetifyProTipTap } from 'vuetify-pro-tiptap'
import 'vuetify-pro-tiptap/style.css'
// import SelectImage from './components/SelectImage.vue'

export const vuetifyProTipTap = createVuetifyProTipTap({
  lang: 'en',
  components: {
    VuetifyTiptap,
    VuetifyViewer
  },
  extensions: [
    BaseKit.configure({
      placeholder: {
        placeholder: 'Enter some text...'
      }
    }),
    Bold,
    Italic,
    Underline,
    Strike,
    Code.configure({ divider: true }),
    Heading,
    TextAlign,
    FontFamily,
    FontSize,
    Color,
    Highlight.configure({ divider: true }),
    SubAndSuperScript.configure({ divider: true }),
    Clear.configure({ divider: true }),
    BulletList,
    OrderedList,
    TaskList,
    Indent.configure({ divider: true }),
    Link,
    // Image.configure({
    //   imageTabs: [{ name: 'SELECT', component: markRaw(SelectImage) }],
    //   // hiddenTabs: ['upload'],
    //   upload(file: File) {
    //     const url = URL.createObjectURL(file)
    //     console.log('mock upload api :>> ', url)
    //     return Promise.resolve(url)
    //   }
    // }),
    Video,
    Table.configure({ divider: true }),
    Blockquote,
    HorizontalRule,
    CodeBlock.configure({ divider: true }),
    History.configure({ divider: true }),
    Fullscreen
  ]
})