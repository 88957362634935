import { createPinia } from 'pinia';
import type { App } from "vue";

export function setupStore(app: App) {
  const store = createPinia()
  app.use(store)
}

export * from './auth';
export * from './notificationReports';
export * from './route';
export * from './subscribe';
export * from './theme';
export * from './users';
export * from './sentInvoices';

