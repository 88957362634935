import axios, { AxiosError, AxiosInstance } from 'axios';

import { localStg } from '@/utils';

export function impersonatedAs() {
  const user = localStg.get('userImp')
  return user && user.userId ? user.userId : null
}

const axiosInstance: AxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_APP_API_URL,
  withCredentials: true,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Credentials': 'true'
  }
});

axiosInstance.interceptors.request.use(
  async config => {
    const handleConfig = { ...config };
    // const impersonatedId = impersonatedAs()
    // if (impersonatedId && handleConfig.url) {
    //   const url = new URL(handleConfig.url, axiosInstance.defaults.baseURL);
    //   url.searchParams.append('impUserId', impersonatedId.toString());
    //   handleConfig.url = url.toString();
    // }
    return handleConfig;
  }
);

axiosInstance.interceptors.response.use(
  async response => {
    return response
  },

  (axiosError: AxiosError) => {
    const { response }:any = axiosError;
    if(response?.status != 201) {
      // console.log(response)
      window.$snackBar?.error(response?.data?.message)
    }
    return

  }
);

export default axiosInstance;
