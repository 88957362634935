import { useRouterPush } from '@/composables';
import i18n from '@/plugins/vue-i18n';
import { router } from '@/router';
import { authAuthenticate, fetchUserInfo } from '@/service';
import { useRouteStore } from '@/store';
import { localStg } from '@/utils';
import { defineStore } from 'pinia';
import { nextTick, unref } from 'vue';
import { clearAuthStorage, getUserInfo } from './helpers';

interface AuthState {
  userInfo: Auth.UserInfo;
  impersonation: boolean
  loginLoading: boolean;
  canPerformOrganizationChanges: boolean,
  canPerformBasicCRUD: boolean,
  userIsAdmin: boolean
}

export const useAuthStore = defineStore('auth-store', {
  state: (): AuthState => ({
    userInfo: getUserInfo(),
    loginLoading: false,
    canPerformOrganizationChanges: false,
    canPerformBasicCRUD: false,
    impersonation: false,
    userIsAdmin: false
  }),

  getters: {
    isLogin(state): boolean {
      return Boolean(state.userInfo && state.userInfo.userId);
    }
  },

  actions: {
    async getLoggedInUser() {
      
      try {
        const userImp = localStg.get('userImp');
        if (userImp && userImp.userId) {
          this.impersonation = true
          await this.setUserInfo(userImp);
        } else {
          this.impersonation = false
          const userInfo = await fetchUserInfo();
          if(!userInfo || !userInfo.userId) {
            throw new Error()
          }
          await this.setUserInfo(userInfo);
        }
      } catch (e) {
        this.resetAuthStore()
      }
    },

    async resetAuthStore() {
      this.impersonation = false
      const { toLogin } = useRouterPush(false);
      const { resetRouteStore } = useRouteStore();
      const route = unref(router.currentRoute);
      clearAuthStorage();
      this.$reset();
      window?.$loadingOverly?.hide()
      window?.$dialog?.closeAll()
      if (route.meta.requiresAuth) {
        toLogin();
      }
      nextTick(() => {
        resetRouteStore();
      });
    },

    async handleActionAfterLogin() {
      const route = useRouteStore();
      const { toLoginRedirect } = useRouterPush(false);
      try {
        await this.getLoggedInUser()
        await route.initAuthRoute();
        toLoginRedirect();

        if (route.isInitAuthRoute) {
          window.$snackBar?.success(`${i18n.global.t("welcomeBack")}，${this.userInfo.userName}!`);
        }
      } catch (error) {
        console.error('Error fetching user info post-login:', error);
        this.resetAuthStore(); // Reset the auth store if fetching user info fails
      }

    },

    // async handleActionAfterSignUp() {
    //   const route = useRouteStore();
    //   const { toLogin } = useRouterPush(false);

    //   try {
    //     toLogin();
    //     if (route.isInitAuthRoute) {
    //       window.$snackBar?.success(`${i18n.global.t("welcomeBack")}，login now!`);
    //     }
    //   } catch (error) {
    //     console.error('Error fetching user info post-login:', error);
    //     this.resetAuthStore(); // Reset the auth store if fetching user info fails
    //   }

    // },

    allowAllRights() {
      this.canPerformOrganizationChanges = true
      this.canPerformBasicCRUD = true
    },

    async setImpersonatedUser(userInfo) {
      if (userInfo) {
        this.impersonation = true
        localStg.set('userImp', userInfo);
        await this.handleActionAfterLogin()
      }
    },

    async exitImpersonation() {
      this.impersonation = false
      localStg.remove('userImp');
      await this.handleActionAfterLogin()

    },

    async setUserInfo(userInfo) {
      let successFlag = false
      if (userInfo) {
        localStg.set('userInfo', userInfo);
        this.userInfo = userInfo;
        let userNotGuest = userInfo.role != 'GUEST'
        let userIsAdmin = userInfo.role == 'ADMIN'
        if(userIsAdmin) {
          this.userIsAdmin = true
        }
        if (userNotGuest) {
          this.allowAllRights()
      
        } else {
          const maxRole = userInfo.role
          if (maxRole == 'VIEWER') {
            this.canPerformBasicCRUD = false
          } else {
            this.canPerformBasicCRUD = true
          }
        }
        successFlag = true;
      }
      return successFlag;
    },

    // async signUp(userData) {
    //   try {
    //     await authRegister(userData);
    //     await this.handleActionAfterSignUp();
    //   } catch (error) {
    //     window.$snackBar?.error(`${error}`);
    //     console.error('Register failed', error);
    //   }
    // },

    // async signUpAuthority(userData) {
    //   try {
    //     await authRegisterAuthority(userData);
    //     await this.handleActionAfterSignUp();
    //   } catch (error) {
    //     window.$snackBar?.error(`${error}`);
    //     console.error('Register failed', error);
    //   }
    // },

    // async resetPassword(userData) {
    //   try {
    //     await authResetPassword(userData);
    //     await this.handleActionAfterSignUp();
    //   } catch (error) {
    //     window.$snackBar?.error(`${error}`);
    //     console.error('Register failed', error);
    //   }
    // },

    // async setAdminPassword(userData) {
    //   try {
    //     await authSetAdminPassword(userData);
    //     await this.handleActionAfterSignUp();
    //   } catch (error) {
    //     window.$snackBar?.error(`${error}`);
    //     console.error('Register failed', error);
    //   }
    // },

    async login(userName: string, password: string) {
      this.loginLoading = true;
      try {
        await authAuthenticate(userName, password);
        await this.handleActionAfterLogin();
      } catch (error) {
        console.error('Login failed', error);
      } finally {
        this.loginLoading = false;
      }
    },
  }
});
