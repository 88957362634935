export default {
  'welcomeBack': 'Bine ai revenit',
  'invalidForm': "Nu ai completat toate campurile obligatorii!",
  apiSuccess: {
    sendForgotPassword: 'E-mailul a fost trimis!',
    resetPassword: 'Parola a fost schimbata cu succes!',
    configurations: {
      create: 'Configuratia a fost creata cu succes!',
      edit: 'Configuratia a fost actualizata!',
      deleteMany: 'Configuratiile selectate au fost sterse!',
      delete: 'Configuratia a fost stearsa!',
      createLinks: "Asocierile au fost salvate cu succes!"
    },
    projects: {
      save: "Proiectul a fost salvat cu succes!!"
    },
    registrationPoints: {
      save: "Punctul de inregistrare a fost salvat!",
      delete: "Punctul de inregistrare a fost sters!",
    },

    registrations: {
      save: "Inregistrarea a fost salvata!",
      delete: "Grupul de voluntari a fost sters!",
    },

    users: {
      create: 'Utilizatorii au fost creati cu succes!',
      edit: 'Utilizatorul a fost actualizat!',
      deleteMany: 'Utilizatorii selectati au fost stersi!',
      delete: 'Utilizatorul a fost sters!',
      resendEmail: 'Email-ul a fost retrimis!',
      inviteAdmin: 'Invitatia a fost trimisa!'
    },
    records: {
      update: "Raportarea a fost actualizata!"
    }

  },
  common: {
    upload: 'Proceseaza',
    add: 'Adauga',
    cancel: 'Anuleaza',
    close: 'Inchide',
    confirm: 'Confirma',
    resendEmail: 'Retrimite invitatie',
    deactivateUser: 'Dezactiveaza',
    activateUser: 'Reactiveaza',
    see_details: 'Vezi detalii',
    description: 'Descriere',
    delete: 'Sterge',
    title: 'Title',
    save: 'Salveaza',
    savePermissions: 'Salveaza permisiuni',
    faq: 'FAQ',
    contact: 'Contacteaza-ne',
    tos: 'Terms of Service',
    policy: 'Privacy Policy'
  },

  permissions: {
    explanation: 'In contextul unui punct de lucru, poti asigna un singur rol pentru acest user.'
  },
  board: {
    titlePlaceholder: 'Enter a title for this card',
    deleteDescription: 'Esti sigur ca vrei sa te deloghezi?',
    editCard: 'Edit Card',
    deleteCard: 'Delete Card',
    state: {
      TODO: 'TO DO',
      INPROGRESS: 'INPROGRESS',
      TESTING: 'TESTING',
      DONE: 'DONE'
    }
  },
  dialogs: {

    createCompanyGroup: 'Creeaza group de companii',
    editCompanyGroup: 'Editeaza grupul de companii',

    configurations: {
      confirmDelete: 'Esti sigur ca vrei sa stergi configuratia selectata?',
      confirmDeleteMany: 'Esti sigur ca vrei sa stergi configuratiile selectate?'
    },

    records: {
      delete: 'Esti sigur ca vrei sa stergi raportarea ',
      deleteMany: 'Esti sigur ca vrei sa stergi raportatile selectate?',
     
    },

    users: {
      create: 'Adauga utilizatori',
      edit: 'Editeaza utilizator',
      selectWorkingPoints_label: 'Puncte de lucru la care va avea acces *',
      selectWorkingPoints_ph: 'Alege punctele de lucru',
      role_label: 'Rolul *',
      role_ph: 'Alege rolul',
      email_label: 'Email utilizator *',
      email_ph: 'Completeaza adresa de email'
    },
    createWorkingPoint: 'Creeaza punct de lucru',

    createCompany: 'Creeaza companie',
    createProvider: 'Creeaza furnizor',
    createProduct: 'Creeaza produs',
    selectCompany: 'Compania',
    selectWorkingPoint: 'Punctul de lucru',
    editProduct: 'Editeaza produs',
    editProvider: 'Editeaza furnizor',

    createConfiguration: 'Adauga integrare cu',
    editConfiguration: 'Editeaza integrare',

    confirmDeleteProvider: 'Esti sigur ca vrei sa stergi furnizorul',
    confirmDeleteProviders: 'Esti sigur ca vrei sa stergi furnizorii selectati?',

    confirmDeleteProduct: 'Esti sigur ca vrei sa stergi produsul',
    confirmDeleteProducts: 'Esti sigur ca vrei sa stergi produsele selectate?',
    confirmDeleteUser: "Esti sigur ca vrei sa stergi acest utilizator? Operatia este ireversibila.",

    name: 'Nume',
    address: 'Adresa',
    city: 'Oras',
    county: 'Judet',
    vatId: 'Cod Fiscal',
    regCom: 'Registrul Comertului',

    providerName: 'Denumire furnizor *',
    providerVatId: 'CIF furnizor *',
    countyOptional: 'Judet (optional)',
    cityOptional: 'Localitate (optional)',
    regComOptional: 'Registrul Comertului (optional)',
    addressOptional: 'Adresa (optional)', 

    selectProvider: 'Furnizor (optional)',
    productCode: 'Cod produs *',
    productName: 'Denumire produs *',
    productProviderName: 'Denumire produs furnizor (optional)',
    unitMeasure: 'Unitate de masura (optional)',
    lastPriceNoTVA: 'Ultimul pret de achizitie fara TVA (optional)',
    tvaQuote: 'Cota TVA (optional)',

  },

  email: {
    compose: 'Compose Email',
    send: 'Send',
    subject: 'Subject',
    labels: 'Labels',
    emptyList: 'Empty email list',
    inbox: 'Inbox',
    sent: 'Sent',
    drafts: 'Drafts',
    starred: 'Starred',
    trash: 'Trash',
    work: 'Work',
    invoice: 'Invoice'
  },
  list: {
    addTask: 'Add Task',
    tasks: 'Tasks',
    completed: 'Completed',
    labels: 'Labels'
  },
  dashboard: {
    acquisitions: 'Achizitii / Furnizor',
    workingPoints: 'Puncte de lucru active',
    users: 'Clienti activi',
    credits_admin: 'Credite cumparate / consumate',
    ratings: 'Ratings',
    credits: 'Credite consumate',
    modifiedPrices: 'Preturi modificate',
    activity: 'Activity',
    weeklySales: 'Weekly Sales',
    sales: 'Sales',
    recentOrders: 'Recent Orders',
    sources: 'Traffic Sources',
    lastweek: 'vs last week',
    orders: 'Orders',
    customers: 'Customers',
    tickets: 'Support Tickets',
    viewReport: 'View Report'
  },
  usermenu: {
    profile: 'Profile',
    signin: 'Sign In',
    dashboard: 'Panou de Bord',
    signout: 'Sign Out',
    notSignin: 'Not Sign in',
  },
  error: {
    notfound: 'Page Not Found',
    other: 'An Error Ocurred'
  },
  check: {
    title: 'Creeaza o parola noua',
    backtosign: 'Back to Sign In',
    newpassword: 'New Parola',
    button: 'Set new password and Sign in',
    error: 'The action link is invalid',
    verifylink: 'Verifying link...',
    verifyemail: 'Verifying email address...',
    emailverified: 'Email verified! Redirecting...'
  },
  forgot: {
    title: 'Ai uitat parola?',
    subtitle: 'Introdu adresa de e-mail si iti vom trimite un link de resetare',
    email: 'Email',
    button: 'Trimite link',
    backtosign: 'Inapoi la login'
  },
  login: {
    title: 'Sign In',
    email: 'Email',
    password: 'Parola',
    button: 'Sign In',
    orsign: 'Or sign in with',
    forgot: 'Am uitat parola',
    noaccount: 'Nu ai inca un cont?',
    create: 'Inregistreaza-te',
    error: 'The email / password combination is invalid',

  },
  register: {
    title: 'Seteaza-ti parola',
    name: 'Nume',
    email: 'Email',
    // companyName: 'Nume Companie',
    // companyAddress: 'Adresa',
    // vatId: 'CUI',
    // regCom: 'Registru Comert',
    // city: 'Oras',
    // county: 'Judet',
    password: 'Parola',
    repeatPassword: 'Repeta parola',
    button: 'Acceseaza contul',
    orsign: 'Or sign up with',
    agree: 'By signing up, you agree to the',
    account: 'Ai deja un cont?',
    signin: 'Sign In'
  },
  utility: {
    maintenance: 'In Maintenance'
  },
  faq: {
    call: 'Have other questions? Please reach out '
  },
  ecommerce: {
    products: 'Products',
    filters: 'Filters',
    collections: 'Collections',
    priceRange: 'Price Range',
    customerReviews: 'Customer Reviews',
    up: 'and up',
    brand: 'Brand',
    search: 'Search for product',
    results: 'Results ( {0} of {1} )',
    orders: 'Orders',
    shipping: 'Shipping',
    freeShipping: 'Free Shipping',
    inStock: 'In Stock',
    quantity: 'Quantity',
    addToCart: 'Add To Cart',
    buyNow: 'Buy Now',
    price: 'Price',
    about: 'About this item',
    description: 'Description',
    reviews: 'Reviews',
    details: 'Product Details',
    cart: 'Cart',
    summary: 'Order Summary',
    total: 'Total',
    discount: 'Discount',
    subtotal: 'Subtotal',
    continue: 'Continue Shopping',
    checkout: 'Checkout'
  },
  menu: {
    help: 'Ajutor',
    faq: 'FAQ',
    search: 'Search (press "ctrl + /" to focus)',
    
    dashboard: 'Panou de Bord',
    records: 'Facturi',
    configurations: 'Configurari',
    users: 'Utilizatori',

    cleanup_form: 'Formular curatenie',
    registration_map: 'Puncte de inregistrare',

    records_list: 'Lista facturi primite',
    records_sent_list: 'Lista facturi trimise',
    records_edit: 'Editeaza raportare',
    reports: 'Rapoarte',
    reports_cleaned: 'Raport des curatate',
    reports_cleaned_ambrosia: 'Raport amb curatata',
    reports_authorities: 'Raport autoritati',
    reports_volunteers: 'Raport voluntari',
    reports_counties: 'Raport sesizari pe judet',
    reports_companies: 'Raport sesizari pe companie',
    reports_wasteTypes: 'Raport tipuri de des',
    reports_sizeWaste: 'Raport dimensiune zone des',
    reports_sizeAmbrosia: 'Raport dimensiune zone amb',

    membership: 'Proiecte',
    membership_projects: 'Lista proiecte',
    membership_projects_edit:' Editeaza proiect',
    membership_registration_points: 'Puncte inregistrare',
    membership_registration_report: 'Raport inscrieri',
    membership_registration_individuals: 'Voluntari individuali',
    membership_registration_groups: 'Grupuri de voluntari',
    users_app: 'Voluntari',
    users_institutions: 'Utilizatori institutie',
    users_admin: 'Administrare utilizatori',

    static_pages: 'Pagini statice',
    complaint_templates: 'Proiecte',
    custom_notifications: 'Notificari custom',
    authorities: 'Autoritati',
    
    logout: 'Logout',
    profile: 'Profil',
    blank: 'Blank Page',
    pages: 'Pages',
    others: 'Others',
    email: 'Email',

    usersList: 'List',
    usersEdit: 'Edit',
  
    auth: 'Auth Pages',
    authLogin: 'Signin / Login',
    authRegister: 'Signup / Register',
    authVerify: 'Verify Email',
    authForgot: 'Ai uitat parola',
    authReset: 'Reseteaza parola',
    errorPages: 'Error Pages',
    errorNotFound: 'Not Found / 404',
    errorUnexpected: 'Unexpected / 500',
    utilityPages: 'Utility Pages',
    utilityMaintenance: 'Maintenance',
    utilitySoon: 'Coming Soon',
    utilityHelp: 'FAQs / Help',
    
    disabled: 'Menu Disabled',
    docs: 'Documentation',
    feedback: 'Feedback',
    support: 'Support',
    landingPage: 'Landing Page',
    pricingPage: 'Pricing Page',
  
  },
  // Vuetify components translations
  $vuetify: {
    badge: 'Badge',
    close: 'Close',
    dataIterator: {
      noResultsText: 'No matching records found',
      loadingText: 'Loading items...'
    },
    dataTable: {
      itemsPerPageText: 'Rows per page:',
      ariaLabel: {
        sortDescending: 'Sorted descending.',
        sortAscending: 'Sorted ascending.',
        sortNone: 'Not sorted.',
        activateNone: 'Activate to remove sorting.',
        activateDescending: 'Activate to sort descending.',
        activateAscending: 'Activate to sort ascending.'
      },
      // sortBy: 'Sort by'
    },
    dataFooter: {
      itemsPerPageText: 'Items per page:',
      itemsPerPageAll: 'All',
      nextPage: 'Next page',
      prevPage: 'Previous page',
      firstPage: 'First page',
      lastPage: 'Last page',
      pageText: '{0}-{1} of {2}'
    },
    datePicker: {
      itemsSelected: '{0} selected',
      nextMonthAriaLabel: 'Next month',
      nextYearAriaLabel: 'Next year',
      prevMonthAriaLabel: 'Previous month',
      prevYearAriaLabel: 'Previous year'
    },
    noDataText: 'Nu exista inregistrari',
    carousel: {
      prev: 'Previous visual',
      next: 'Next visual',
      ariaLabel: {
        delimiter: 'Carousel slide {0} of {1}'
      }
    },
    calendar: {
      moreEvents: '{0} more'
    },
    fileInput: {
      counter: '{0} files',
      counterSize: '{0} files ({1} in total)'
    },
    timePicker: {
      am: 'AM',
      pm: 'PM'
    },
    pagination: {
      ariaLabel: {
        wrapper: 'Pagination Navigation',
        next: 'Next page',
        previous: 'Previous page',
        page: 'Goto Page {0}',
        currentPage: 'Current Page, Page {0}'
      }
    }
  }
}
