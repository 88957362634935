import { routeElements } from "../elements";

export const routeModel: Record<Auth.RoleType, AuthRoute.Route[]> = {
  ADMIN: [
    routeElements.DASHBOARD_ANALYTICS,
    routeElements.RECORDS,
    // routeElements.MEMBERSHIP,
    routeElements.USERS,
    routeElements.CONFIGURATIONS
  ],
  COORDONATOR: [
    routeElements.DASHBOARD_ANALYTICS,
    routeElements.RECORDS,
    // routeElements.MEMBERSHIP,
    // routeElements.USERS,
    // routeElements.CONFIGURATIONS
  ],
  AUTHORITY: [
    // routeElements.DASHBOARD_ANALYTICS,
    routeElements.RECORDS_AUTHORITIES,
 
  ],
  CONTABILITATE: [
    routeElements.DASHBOARD_ANALYTICS,
    routeElements.RECORDS,
  ],
  USER: [
    routeElements.DASHBOARD_ANALYTICS,
    routeElements.RECORDS,
  ],
  
};
