import { useThemeStore } from "@/store";
import { useEventListener } from '@vueuse/core';

export function useGlobalEvents() {
  const appConifg = useThemeStore();

  useEventListener(window, 'beforeunload', () => {
    appConifg.cacheThemeSettings();
  });
}
