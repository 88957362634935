import axiosInstance from '~/build/plugins/axios';
// import i18n from '~/src/plugins/vue-i18n';
import { routeModel } from '~/src/router/routes/route';

export const authAuthenticate = async (username: string, password: string) => {
  const loginResult = await axiosInstance.post('/auth/login', {
    email: username,
    password: password,
  });
  // console.log('loginResult', loginResult);

  // aici de facut mesajul cu verde pentru "Inregistrarea a fost facuta cu succes. In curand administratorul iti va activa contul."

  if (loginResult.status == 401) {
    window.$snackBar?.error(loginResult.data.message)
    throw loginResult
  }
};

export const fetchUserInfo = async () => {
  const data = await axiosInstance.get('/auth/user');
  let user = data.data
  return {
    userId: user._id,
    userName: user.name,
    userAvatar: user.avatar,
    role: user.role,
    permissions: user.permissions,
    step: user.step,
    companies: user.companies
  }
}

export function fetchUserRoutes(userInfo) {
  const maxRole = userInfo.role
  let routeHomeName: AuthRoute.LastDegreeRouteKey = 'dashboard_analytics';
  const filterRoutes = routeModel[maxRole ? maxRole.toUpperCase() : ''] || []

  if (filterRoutes.length) {
    routeHomeName = filterRoutes[0].name
  }
  return {
    code: 200,
    message: 'ok',
    data: {
      routes: filterRoutes,
      home: routeHomeName
    }
  };
}
