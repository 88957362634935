import type { RouteComponent } from 'vue-router';

export const views: Record<PageRoute.LastDegreeRouteKey,
  RouteComponent | (() => Promise<{ default: RouteComponent }>)> = {
  404: () => import('./_builtin/error/NotFoundPage.vue'),
  403: () => import('./_builtin/error/NotFoundPage.vue'),
  500: () => import('./_builtin/error/UnexpectedPage.vue'),
  login: () => import('./_builtin/auth/index.vue'),
  'not-found': () => import('./_builtin/error/NotFoundPage.vue'),
  'profile': () => import('./profile/ProfilePage.vue'),
  'registration-map': () => import('./_builtin/registration-map/index.vue'),

  'dashboard_analytics': () => import('./dashboard/index.vue'),

  "records_list": () => import('./records-list/RecordsListPage.vue'),

  "records_sent_list": () => import('./records-sent-list/SentRecordsListPage.vue'),

  "records_edit": () => import('./records-list/EditRecordPage.vue'),

  "records_sent_edit": () => import('./records-sent-list/EditSentRecordPage.vue'),

  "reports_cleaned": () => import('./reports/ReportsPage.vue'),

  "users_admin": () => import('./users-admin/UsersAdminPage.vue'),
  // "configurations_static-pages": () => import('./static-pages/StaticPagesPage.vue'),
  "configurations_projects": () => import('./configurations/ProjectsTemplatesPage.vue'),
  // "configurations_custom-notifications": () => import('./custom-notifications/CustomNotificationsPage.vue'),

};
