export const routeElements: Record<string, AuthRoute.Route> = {
  DASHBOARD_ANALYTICS: {
    name: 'dashboard_analytics',
    path: '/dashboard/analytics',
    component: 'basic',
    children: [
      {
        name: 'dashboard_analytics',
        path: '/dashboard/analytics',
        component: 'self',
        meta: {
          icon: 'mdi-view-dashboard-outline',
          title: 'menu.dashboard',
          requiresAuth: true,
          context: 'working_point'
        }
      },
      {
        name: 'profile',
        path: '/profile',
        component: 'self',
        meta: {
          icon: 'mdi-view-dashboard-outline',
          title: 'menu.profile',
          requiresAuth: true,
          hide: true
        }
      }
    ],
    meta: {
      title: 'menu.dashboard',
      icon: 'mdi-view-dashboard-outline',
      order: 1,
      requiresAuth: true
    }
  },

  RECORDS: {
    name: 'records',
    path: '/records',
    component: 'basic',
    children: [
      {
        name: 'records_list',
        path: '/records/list',
        component: 'self',
        meta: {
          title: 'menu.records_list',
          icon: 'mdi-format-list-checkbox',
          order: 2,
          requiresAuth: true
        }
      },
      {
        name: 'records_sent_list',
        path: '/records/sent/list',
        component: 'self',
        meta: {
          title: 'menu.records_sent_list',
          icon: 'mdi-format-list-checkbox',
          order: 2,
          requiresAuth: true
        }
      },
      {
        name: 'records_edit',
        path: '/records/edit',
        component: 'self',
        meta: {
          title: 'menu.records_edit',
          dynamicPath: '/records/edit/:id?',
          requiresAuth: true,
          hide: true
        }
      },
      {
        name: 'records_sent_edit',
        path: '/records/sent/edit',
        component: 'self',
        meta: {
          title: 'menu.records_sent_edit',
          dynamicPath: '/records/sent/edit/:id?',
          requiresAuth: true,
          hide: true
        }
      }

      // {
      //   name: 'reports',
      //   path: '/reports',
      //   component: 'blank',
      //   children: [
      //     {
      //       name: 'reports_cleaned',
      //       path: '/reports/cleaned',
      //       component: 'self',
      //       meta: {
      //         title: 'menu.reports_cleaned',
      //         requiresAuth: true,
      //         order: 1,
      //         icon: 'mdi-format-list-checkbox',
      //       }
      //     },
      //     {
      //       name: 'reports_cleaned-ambrosia',
      //       path: '/reports/cleaned-ambrosia',
      //       component: 'self',
      //       meta: {
      //         title: 'menu.reports_cleaned_ambrosia',
      //         requiresAuth: true,
      //         order: 1,
      //         icon: 'mdi-format-list-checkbox',
      //       }
      //     },
      //     {
      //       name: 'reports_authorities',
      //       path: '/reports/authorities',
      //       component: 'self',
      //       meta: {
      //         title: 'menu.reports_authorities',
      //         requiresAuth: true,
      //         order: 1,
      //         icon: 'mdi-format-list-checkbox',
      //       }
      //     },
      //     {
      //       name: 'reports_volunteers',
      //       path: '/reports/volunteers',
      //       component: 'self',
      //       meta: {
      //         title: 'menu.reports_volunteers',
      //         requiresAuth: true,
      //         order: 2,
      //         icon: 'mdi-format-list-checkbox',
      //       }
      //     },
      //     {
      //       name: 'reports_counties',
      //       path: '/reports/counties',
      //       component: 'self',
      //       meta: {
      //         title: 'menu.reports_counties',
      //         requiresAuth: true,
      //         order: 3,
      //         icon: 'mdi-format-list-checkbox',
      //       }
      //     },
      //     {
      //       name: 'reports_wasteTypes',
      //       path: '/reports/wasteTypes',
      //       component: 'self',
      //       meta: {
      //         title: 'menu.reports_wasteTypes',
      //         requiresAuth: true,
      //         order: 4,
      //         icon: 'mdi-format-list-checkbox',
      //       }
      //     },
      //     {
      //       name: 'reports_sizeWaste',
      //       path: '/reports/sizeWaste',
      //       component: 'self',
      //       meta: {
      //         title: 'menu.reports_sizeWaste',
      //         requiresAuth: true,
      //         order: 5,
      //         icon: 'mdi-format-list-checkbox',
      //       }
      //     },
      //     {
      //       name: 'reports_sizeAmbrosia',
      //       path: '/reports/sizeAmbrosia',
      //       component: 'self',
      //       meta: {
      //         title: 'menu.reports_sizeAmbrosia',
      //         requiresAuth: true,
      //         order: 6,
      //         icon: 'mdi-format-list-checkbox',
      //       }
      //     },
      //     {
      //       name: 'reports_companies',
      //       path: '/reports/companies',
      //       component: 'self',
      //       meta: {
      //         title: 'menu.reports_companies',
      //         requiresAuth: true,
      //         order: 7,
      //         icon: 'mdi-format-list-checkbox',
      //       }
      //     },
      //   ],
      //   meta: {
      //     title: 'menu.reports',
      //     icon: 'mdi-file-chart-outline',
      //     order: 2,
      //     requiresAuth: true,

      //   },

      // },
    ],
    meta: {
      title: 'menu.records',
      requiresAuth: true
    }
  },

  MEMBERSHIP: {
    name: 'membership',
    path: '/membership',
    component: 'basic',
    children: [
      {
        name: 'membership_projects',
        path: '/membership/projects',
        component: 'self',
        meta: {
          title: 'menu.membership_projects',
          icon: 'mdi-rocket-launch-outline',
          order: 1,
          requiresAuth: true
        }
      },
      {
        name: 'membership_projects_edit',
        path: '/membership/projects/edit',
        component: 'self',
        meta: {
          title: 'menu.membership_projects_edit',
          dynamicPath: '/membership/projects/edit/:id?',
          requiresAuth: true,
          hide: true
        }
      },
      {
        name: 'membership_registration-individuals',
        path: '/membership/registration-individuals',
        component: 'self',
        meta: {
          title: 'menu.membership_registration_individuals',
          icon: 'mdi-account-outline',
          order: 2,
          requiresAuth: true
        }
      },
      {
        name: 'membership_registration-groups',
        path: '/membership/registration-groups',
        component: 'self',
        meta: {
          title: 'menu.membership_registration_groups',
          icon: 'mdi-account-group-outline',
          order: 3,
          requiresAuth: true
        }
      },
      {
        name: 'membership_registration-points',
        path: '/membership/registration-points',
        component: 'self',
        meta: {
          title: 'menu.membership_registration_points',
          icon: 'mdi-map-marker-outline',
          order: 4,
          requiresAuth: true
        }
      },
      {
        name: 'membership_registration-report',
        path: '/membership/registration-report',
        component: 'self',
        meta: {
          title: 'menu.membership_registration_report',
          icon: 'mdi-map-marker-account-outline',
          order: 5,
          requiresAuth: true
        }
      }
    ],
    meta: {
      title: 'menu.membership',
      requiresAuth: true
    }
  },

  RECORDS_AUTHORITIES: {
    name: 'records',
    path: '/records',
    component: 'basic',
    children: [
      {
        name: 'records_list',
        path: '/records/list',
        component: 'self',
        meta: {
          title: 'menu.records_list',
          icon: 'mdi-format-list-checkbox',
          order: 2,
          requiresAuth: true
        }
      },
      {
        name: 'records_edit',
        path: '/records/edit',
        component: 'self',
        meta: {
          title: 'menu.records_edit',
          dynamicPath: '/records/edit/:id?',
          requiresAuth: true,
          hide: true
        }
      }
    ],
    meta: {
      title: 'menu.records',
      requiresAuth: true
    }
  },

  USERS: {
    name: 'users',
    path: '/users',
    component: 'basic',
    children: [
      // {
      //   name: 'users_app',
      //   path: '/users/app',
      //   component: 'self',
      //   meta: {
      //     title: 'menu.users_app',
      //     icon: 'mdi-account-group-outline',
      //     order: 2,
      //     requiresAuth: true,
      //   }
      // },
      // {
      //   name: 'users_institutions',
      //   path: '/users/institutions',
      //   component: 'self',
      //   meta: {
      //     title: 'menu.users_institutions',
      //     icon: 'mdi-city-variant-outline',
      //     order: 2,
      //     requiresAuth: true,
      //   }
      // },
      {
        name: 'users_admin',
        path: '/users/admin',
        component: 'self',
        meta: {
          title: 'menu.users_admin',
          icon: 'mdi-badge-account-outline',
          order: 2,
          requiresAuth: true
        }
      }
    ],
    meta: {
      title: 'menu.users',
      requiresAuth: true
    }
  },

  CONFIGURATIONS: {
    name: 'configurations',
    path: '/configurations',
    component: 'basic',
    children: [
      // {
      //   name: 'configurations_static-pages',
      //   path: '/configurations/static-pages',
      //   component: 'self',
      //   meta: {
      //     title: 'menu.static_pages',
      //     icon: 'mdi-file-outline',
      //     order: 1,
      //     requiresAuth: true,

      //   }
      // },
      {
        name: 'configurations_projects',
        path: '/configurations/projects',
        component: 'self',
        meta: {
          title: 'menu.complaint_templates',
          icon: 'mdi-file-cog-outline',
          order: 2,
          requiresAuth: true
        }
      }
      // {
      //   name: 'configurations_custom-notifications',
      //   path: '/configurations/custom-notifications',
      //   component: 'self',
      //   meta: {
      //     title: 'menu.custom_notifications',
      //     icon: 'mdi-bell-outline',
      //     order: 3,
      //     requiresAuth: true,
      //   }
      // },
      // {
      //   name: 'configurations_authorities',
      //   path: '/configurations/authorities',
      //   component: 'self',
      //   meta: {
      //     title: 'menu.authorities',
      //     icon: 'mdi-table-account',
      //     order: 4,
      //     requiresAuth: true,
      //   }
      // },
    ],
    meta: {
      title: 'menu.configurations',
      requiresAuth: true
    }
  }
}
