import { localStg } from '@/utils';

export function isLoggedIn() {
  const user = localStg.get('userInfo')
  return user && user.userId ? true : false
}

export function getUserInfo() {
  const emptyInfo: Auth.UserInfo = {
    userId: '',
    userName: '',
    role: 'ADMIN',
    companies: []
  };
  return emptyInfo;
}

export function clearAuthStorage() {
  localStg.remove('userInfo');
  localStg.remove('userImp');
}
