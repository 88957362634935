import en from '../translations/en'
import ro from '../translations/ro'

let locale = 'ro'

export default {
  locale,

  fallbackLocale: 'en',

  availableLocales: [{
    code: 'en',
    flag: 'us',
    label: 'English',
    messages: en
  }, {
    code: 'ro',
    flag: 'ro',
    label: 'Romana',
    messages: ro
  }]
}
