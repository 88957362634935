import i18n from '@/plugins/vue-i18n';
import { useTitle } from '@vueuse/core';
import type { Router } from 'vue-router';
import { createPermissionGuard } from './permission';

export function createRouterGuard(router: Router) {
  router.beforeEach(async (to, from, next) => {
    await createPermissionGuard(to, from, next);
  });
  router.afterEach(to => {
    useTitle(i18n.global.t(to.meta.title));
  });
}
